<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <user-list-assign-partner
      :is-assign-partner-sidebar-active.sync="isAssignPartnerSidebarActive"
      :selected-user-id="selectedUserId"
      :selected-partner-id="selectedPartnerId"
      :user-id="selectedUserId"
      @refetch-data="refetchData"
    />
    <user-assign-role
      :is-assign-role-sidebar-active.sync="isAssignRoleSidebarActive"
      :selected-user-id="selectedUserId"
      :selected-role-id="selectedRoleId"
      :user-id="selectedUserId"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :type-filter.sync="typeFilter"
      :status-filter.sync="statusFilter"
      :is-partner-assigned.sync="isPartnerAssigned"
      :role-options="roleOptions"
      :type-options="typeOptions"
      :status-options="statusOptions"
      :partner-options="partnerOptions"
      :reward-activated-options="rewardActivatedOptions"
      :reward-activated-filter.sync="rewardActivatedFilter"
      :hash-submitted-filter.sync="hashSubmittedFilter"
      :hash-submitted-options="hashSubmittedOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="isLoading ? 'Please wait...' : 'No records found'"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(createdAt)="data">
          {{ moment(data.item.createdAt).format("DD-MM-YYYY") }}
        </template>
        <!-- Column: User -->
        <template #cell(username)="data">
          <b-link v-if="data.item.isActive" target="_blank" :to="`profile/${data.item.username}`">{{
            data.item.username
          }}</b-link>
          <strong v-if="!data.item.isActive">{{ data.item.username }}</strong>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <b-row>
            <b-col cols="6">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.role && data.item.role.name"
                  :icon="resolveUserRoleIcon(data.item.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(data.item.role.name)}`"
                />
                <span
                  v-if="data.item.role && data.item.role.name"
                  class="align-text-top text-capitalize"
                  >{{ data.item.role.name }}</span
                >
              </div></b-col
            >
            <b-col cols="6"
              ><b-button
                variant="primary"
                size="sm"
                @click="openAssignUpdateRoleSidebar(data.item._id)"
                >Assign</b-button
              ></b-col
            >
          </b-row>
        </template>
        <!-- Column: Type -->
        <template #cell(isActive)="data">
          <b-form-checkbox
            v-model="data.item.isActive"
            switch
            class="custom-control-success"
            @change="changeUser(data.item._id, 'isActive', $event)"
          />
        </template>

        <template #cell(userType)="data">
          <!-- <v-select
            v-model="data.item.userType"
            :options="typeOptions"
            @input="changeUser(data.item._id, 'userType', $event)"
          /> -->
          {{ data.item.userType }}
        </template>

        <template #cell(isVerified)="data">
          <b-badge :variant="data.item.isVerified ? 'success' : 'danger'">{{
            data.item.isVerified ? "Yes" : "No"
          }}</b-badge>
        </template>

        <template #cell(partnerId)="data">
          <b-link v-if="data.item.partnerId" target="_blank" :to="`/partners/view/${data.item._id}`"
            >{{ data.item.partnerId.username }}<br
          /></b-link>
          <b-button
            variant="secondary"
            size="sm"
            @click="
              openAssignUpdatePartnerSidebar(
                data.item._id,
                data.item.partnerId ? data.item.partnerId._id : ''
              )
            "
            >{{ data.item.partnerId ? "Update" : "Assign" }}</b-button
          >
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item target="_blank" :to="`/apps/users/edit/${data.item._id}`">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!data.item.rewardActivated"
              target="_blank"
              :to="`/users/activate-reward/${data.item._id}`"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Activate Reward</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="openUpdateUser(data.item._id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";

import UserListAddNew from "./UserListAddNew.vue";
import UserListAssignPartner from "./UserListAssignPartner.vue";
import UserAssignRole from "./UserAssignRole.vue";

import axios from "axios";
import moment from "moment";

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    UserListAssignPartner,
    UserAssignRole,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,

    vSelect,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    const isAddNewUserSidebarActive = ref(false);
    const isAssignPartnerSidebarActive = ref(false);
    const isAssignRoleSidebarActive = ref(false);

    const roleOptions = [
      { label: "Super Admin", value: "super-admin" },
      { label: "Employee", value: "employee" },
      { label: "User", value: "user" },
    ];

    const typeOptions = [
      { label: "Company", value: "company" },
      { label: "Freelancer", value: "freelancer" },
      { label: "Investor", value: "investor" },
      { label: "Partner", value: "partner" },
      { label: "Advisor", value: "advisor" },
      { label: "Broker", value: "broker" },
    ];

    const statusOptions = [
      { label: "Active", value: "1" },
      { label: "Disabled", value: "0" },
    ];

    const rewardActivatedOptions = [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ];

    const hashSubmittedOptions = [
      { label: "Yes", value: "true" },
      /* { label: "No", value: "0" }, */
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      typeFilter,
      statusFilter,
      rewardActivatedFilter,
      hashSubmittedFilter,
      currentUserData,
      changeUser,
      partnerOptions,
      isPartnerAssigned,
      getPartners,
      partners,
      getRoles,
      roles,
      selectedRoleId,
      selectedPartnerId,
      selectedUserId,
      isLoading,
    } = useUsersList();

    const openAssignUpdatePartnerSidebar = (userId, partnerId) => {
      selectedPartnerId.value = partnerId;
      selectedUserId.value = userId;
      isAssignPartnerSidebarActive.value = true;
    };
    const openAssignUpdateRoleSidebar = (userId, partnerId) => {
      selectedRoleId.value = partnerId;
      selectedUserId.value = userId;
      isAssignRoleSidebarActive.value = true;
    };

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      isAssignPartnerSidebarActive,
      openAssignUpdatePartnerSidebar,
      openAssignUpdateRoleSidebar,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      typeOptions,
      statusOptions,
      rewardActivatedOptions,
      hashSubmittedOptions,

      // Extra Filters
      roleFilter,
      typeFilter,
      statusFilter,
      rewardActivatedFilter,
      hashSubmittedFilter,
      currentUserData,
      changeUser,
      partnerOptions,
      isPartnerAssigned,
      getPartners,
      getRoles,
      roles,
      partners,
      selectedPartnerId,
      selectedUserId,
      selectedRoleId,
      isAssignRoleSidebarActive,
      isLoading,
    };
  },

  methods: {
    moment: function (input) {
      return moment(input);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
