<template>
  <b-sidebar
    id="assign-partner-sidebar"
    :visible="isAssignPartnerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-assign-partner-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Assign / Update User</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <div class="p-3">
        <!-- Username -->
        <b-form-group label="Select Partner" label-for="selectedPartnerId">
          <v-select
            rules="required|alpha-num"
            name="Partner"
            @search="getPartners"
            :options="partners"
            v-model="selectedPartnerId"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="onSubmit"
            variant="primary"
            class="mr-2"
          >
            Update / Assign Partner
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import useUsersList from "./useUsersList";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },

  directives: {
    Ripple,
  },
  model: {
    prop: "isAssignPartnerSidebarActive",
    event: "update:is-assign-partner-sidebar-active",
  },
  props: {
    isAssignPartnerSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  setup(props, { emit }) {
    const { getPartners, partners, updatePartner, selectedPartnerId, selectedUserId } =
      useUsersList();

    const onSubmit = async () => {
      try {
        const response = await updatePartner(props.userId, selectedPartnerId.value);
        emit("refetch-data");
        emit("update:is-assign-partner-sidebar-active", false);
      } catch (err) {}
    };

    return {
      partners,
      getPartners,
      updatePartner,
      selectedUserId,
      selectedPartnerId,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
