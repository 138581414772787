import { ref, watch, computed } from "@vue/composition-api";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);
  const selectedPartnerId = ref("");
  const selectedRoleId = ref(null);
  const isLoading = ref(false);

  const roles = ref([]);

  const selectedUserId = ref("");
  const partners = ref([]);

  const partnerOptions = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];

  // Table Handlers
  const tableColumns = [
    { key: "createdAt", sortable: true },
    { key: "username", sortable: true },
    { key: "email", sortable: true },
    { key: "role", sortable: true },
    {
      key: "userType",
      label: "Type",
      formatter: title,
      sortable: true,
    },
    {
      key: "isVerified",
      label: "Verified",
    },
    /* { key: 'partnerId', sorttable: false, label: 'Partner' }, */
    { key: "isActive", sortable: true, label: "Status" },
    { key: "actions", label: "" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("createdAt");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const typeFilter = ref(null);
  const statusFilter = ref(null);
  const rewardActivatedFilter = ref(null);
  const hashSubmittedFilter = ref(null);
  const isPartnerAssigned = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      typeFilter,
      statusFilter,
      isPartnerAssigned,
      rewardActivatedFilter,
      hashSubmittedFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", searchQuery.value);
      formData.append("role", roleFilter.value ? roleFilter.value : "");
      formData.append("page", currentPage.value);
      formData.append("perPage", perPage.value);
      formData.append("status", statusFilter.value ? statusFilter.value : "");
      formData.append("type", typeFilter.value ? typeFilter.value : "");
      formData.append("sortBy", sortBy.value ? sortBy.value : "");
      formData.append("sortDesc", isSortDirDesc.value);
      formData.append("isPartnerAssigned", isPartnerAssigned.value ? isPartnerAssigned.value : "");
      formData.append("hashSubmitted", hashSubmittedFilter.value ? hashSubmittedFilter.value : "");
      formData.append(
        "rewardActivated",
        rewardActivatedFilter.value ? rewardActivatedFilter.value : ""
      );
      const response = await axios.post("users/search", formData);
      const { data } = response;
      totalUsers.value = data.count;
      return data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            error.response.status === 401
              ? "you dont have permissions to do this action"
              : "Error fetching users list",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const getPartners = async (search, loading) => {
    try {
      const data = new FormData();
      data.append("type", "partner");
      data.append("q", search);
      const response = await axios.post("users/search", data);

      const output = [];
      if (response.data.data.length) {
        response.data.data.map((item) => {
          output.push({
            value: item._id,
            label: item.username,
          });
        });
      }
      partners.value = output;
    } catch (error) {
      console.log("We are unabel to get the partners");
    }
  };
  const getRoles = async () => {
    try {
      const response = await axios.get("roles");

      const output = [];
      if (response.data.data.length) {
        response.data.map((item) => {
          output.push({
            value: item._id,
            label: item.username,
          });
        });
      }
      roles.value = output;
    } catch (error) {
      console.log("We are unabel to get the roles");
    }
  };
  const updatePartner = async (userId, partnerId) => {
    try {
      const data = new FormData();
      data.append("userId", userId);
      data.append("partnerId", partnerId ? partnerId.value : null);
      const response = await axios.put("users/", data);
      toast({
        component: ToastificationContent,
        props: {
          title: "Partner Updated Successfully",
          variant: "success",
        },
      });
      return response;
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: JSON.stringify(err.response.data.error.message),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };
  const updateUserRole = async (userId, roleId) => {
    try {
      const data = new FormData();
      data.append("userId", userId);
      data.append("roleId", roleId ? roleId.value : null);
      const response = await axios.post("set/user/role", data);
      toast({
        component: ToastificationContent,
        props: {
          title: "Role assigned Successfully",
          variant: "success",
        },
      });
      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            error.response.status === 401
              ? "you dont have permissions to do this action"
              : JSON.stringify(err.response.data.error.message),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const addUser = async (fields) => {
    try {
      const fd = new FormData();
      fd.append("username", fields.username);
      fd.append("email", fields.email);
      fd.append("password", fields.password);
      fd.append("role", fields.role);

      const response = await axios.post("users", fd);
      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response.data;
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: JSON.stringify(err.response.data.error.message),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";

    if (role == "super-admin") return "success";
    if (role == "partner") return "info";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role == "super-admin") return "ServerIcon";
    if (role == "partner") return "DatabaseIcon";

    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === true) return "success";
    if (status === false) return "danger";
    return "primary";
  };

  const changeUser = async (userId, field, event) => {
    try {
      const fieldValue = field == "userType" ? event.value : event;
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append(field, fieldValue);
      const response = await axios.put("/users/", formData);
      console.log(event);
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: JSON.stringify(err.response.data.error.message),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    typeFilter,
    statusFilter,
    rewardActivatedFilter,
    hashSubmittedFilter,
    addUser,
    changeUser,
    partnerOptions,
    isPartnerAssigned,
    getPartners,
    getRoles,
    selectedPartnerId,
    selectedUserId,
    partners,
    roles,
    selectedRoleId,
    updatePartner,
    updateUserRole,
    isLoading,
  };
}
