<template>
  <b-sidebar
    id="assign-role-sidebar"
    :visible="isAssignRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-assign-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Assign / User Role
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div class="p-3">
        <!-- Username -->
        <b-form-group
          label="Select Role"
          label-for="selectedPartnerId"
        >
          <div id="app">
            <treeselect
              v-model="selectedRoleId"
              :multiple="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="assign Role ."
              :options="calcRoles"
            />
          </div>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            Update / Assign Role
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import useUsersList from './useUsersList'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BFormGroup,
    BButton,
    Treeselect,
  },

  directives: {
    Ripple,
  },
  model: {
    prop: 'isAssignRoleSidebarActive',
    event: 'update:is-assign-role-sidebar-active',
  },
  props: {
    isAssignRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      roles: 'profile/getAllRoles',
    }),
    calcRoles() {
      return this.roles.map(row => ({ id: row._id, label: row.name }))
    },
  },
  methods: {
    ...mapActions({
      fetchRoles: 'profile/retreiveRoles',
    }),
  },
  created() {
    this.fetchRoles()
  },

  setup(props, { emit }) {
    const {
     updateUserRole, selectedRoleId, selectedUserId,
    } = useUsersList()

    const onSubmit = async () => {
      try {
        const response = await updateUserRole(props.userId, selectedRoleId)
        emit('refetch-data')
        emit('update:is-assign-role-sidebar-active', false)
      } catch (err) {}
    }

    return {
      updateUserRole,
      selectedUserId,
      selectedRoleId,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
